// Generated by Framer (38fb4ea)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["UygD0pvuW"];

const serializationHash = "framer-g9jzu"

const variantClassNames = {UygD0pvuW: "framer-v-189ob0v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, width, ...props}) => { return {...props, CtLG2LLxu: image ?? props.CtLG2LLxu} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CtLG2LLxu, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "UygD0pvuW", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-189ob0v", className, classNames)} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"UygD0pvuW"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backdropFilter: "blur(5px)", backgroundColor: "rgba(102, 112, 255, 0.05)", borderBottomLeftRadius: 48, borderBottomRightRadius: 48, borderTopLeftRadius: 48, borderTopRightRadius: 48, WebkitBackdropFilter: "blur(5px)", ...style}}><Image background={{alt: "", fit: "fill", sizes: `max(${componentViewport?.width || "100vw"} - 48px, 0px)`, ...toResponsiveImage(CtLG2LLxu)}} className={"framer-xnjvis"} data-framer-name={"Photo 1"} layoutDependency={layoutDependency} layoutId={"FNY75LeMD"} style={{borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-g9jzu.framer-1309wgk, .framer-g9jzu .framer-1309wgk { display: block; }", ".framer-g9jzu.framer-189ob0v { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 24px; position: relative; width: 423px; will-change: var(--framer-will-change-override, transform); }", ".framer-g9jzu .framer-xnjvis { aspect-ratio: 1.4136546184738956 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 265px); position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-g9jzu.framer-189ob0v { gap: 0px; } .framer-g9jzu.framer-189ob0v > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-g9jzu.framer-189ob0v > :first-child { margin-left: 0px; } .framer-g9jzu.framer-189ob0v > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 313
 * @framerIntrinsicWidth 423
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"CtLG2LLxu":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLbIiEwjhg: React.ComponentType<Props> = withCSS(Component, css, "framer-g9jzu") as typeof Component;
export default FramerLbIiEwjhg;

FramerLbIiEwjhg.displayName = "Project Photo Card";

FramerLbIiEwjhg.defaultProps = {height: 313, width: 423};

addPropertyControls(FramerLbIiEwjhg, {CtLG2LLxu: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerLbIiEwjhg, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})